html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--ifm-font-family-base);
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  body * {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}
blockquote,
body,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
li,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

pre {
  padding: 1rem;
}
ul {
  list-style: none;
}

button,
input,
select,
textarea {
  color: inherit;
  letter-spacing: inherit;
  font: inherit;
}

/* input[type='text'],
textarea {
  width: 100%;
} */

fieldset {
  padding: 0;
  border: none;
}

legend {
  margin-bottom: 0.5rem;
  max-width: 100%;
}

button,
input,
textarea {
  border: 1px solid gray;
}

button {
  border-radius: 0.125rem;

  padding: 0;
}

button * {
  pointer-events: none;
}

button:hover {
  cursor: pointer;
}

embed,
iframe,
img,
object,
svg,
video {
  display: block;
  max-width: 100%;
}

table {
  width: 100%;
  table-layout: fixed;
}

[hidden] {
  display: none !important;
}

noscript {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* [tabindex='-1'] {
  outline: none !important;
  box-shadow: none !important;
} */
