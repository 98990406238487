@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .output-component {
  }

  .input-text-component,
  .output-text-component {
    height: 100%;
    width: 100%;
    max-height: 20rem;
    resize: none;
    border: none;
  }

  .output-image-component-wrapper,
  .input-image-component-wrapper {
    height: 100%;
    max-height: 20rem;
    display: flex;
  }
  .card {
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.6');
    box-shadow: theme('boxShadow.xl');
  }
  @media (min-width: 728px) {
    .output-image-component-wrapper,
    .input-image-component-wrapper {
    }
  }
  @media (min-width: 1020px) {
    .output-image-component-wrapper,
    .input-image-component-wrapper,
    .input-text-component,
    .output-text-component {
      height: 100%;
      max-height: 100%;
    }
  }
}
@layer base {
  html {
    font-family: system-ui, -apple-system, sans-serif;
  }
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
